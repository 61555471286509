.radio-details-player {
  margin-bottom: rem(50px);
  @extend .d-flex;
  .thumb-area {
    width: 306px;
    padding: rem(40px);
    background-color: #0b1834;
    @include border-radius(30px);
    @extend .text-center;
    @extend .position-relative;
    .radio-status {
      @extend .position-absolute;
      top: 20px;
      right: 20px;
      padding: rem(2px) rem(15px) rem(2px) rem(30px);
      background-color: #28344c;
      @include border-radius(99px);
      font-size: rem(16px);
      @extend .text-base;
      &::before {
        @extend .position-absolute;
        content: "";
        top: 50%;
        left: 12px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #e59f38;
        margin-top: -4px;
      }
    }
    img {
      max-height: 117px;
    }
  }
  .content-area {
    width: calc(100% - 306px);
    padding-left: rem(60px);
    @include media(767px) {
      width: 100%;
      padding-left: 0;
      margin-top: rem(20px);
    }
    .single-audio-player {
      margin-top: rem(30px);
      .single-audio-content {
        padding-left: 0;
        width: 100%;
        .audio-control {
          align-items: flex-end;
          .fast-reverse,
          .fast-forward {
            display: none;
          }
          .time-keep {
            right: auto;
            top: auto;
            bottom: 23px;
            left: 140px;
            &::after {
              content: "min";
              margin-left: rem(5px);
              font-size: rem(16px);
            }
          }
          .play {
            width: 110px;
            height: 50px;
            @extend .site-color;
            @include border-radius(10px);
            display: flex;
            margin: 0;
            &::after {
              background-image: url("../images/icons/play-black.svg");
              order: -1;
              width: 19px;
              height: 19px;
              border: 2px solid #000;
              border-radius: 50%;
              background-size: 10px 10px;
              background-repeat: no-repeat;
              background-position: 55% 60%;
              margin-right: rem(10px);
            }
            &::before {
              content: "Play";
              color: #000;
              font-size: rem(18px);
              font-weight: 600;
            }
          }
        }
        .maudio.playing {
          .play {
            &::after {
              background-image: url("../images/icons/pause-black.svg");
            }
          }
        }
      }
    }
  }
}
